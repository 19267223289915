<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <table-box
      :columns="columns"
      :rows="newTasks"
      :baseUrl="baseUrl"
      :loading="loading"
      :pagination="pagination"
      emptyText="No Task Found."
      :showAdd="false"
      :showFilter="false"
      :showPagination="true"
      :showToolbar="true"
      :showDownload="false"
      :searchItems="searchItems"
      @submit="onSubmit"
    >
    </table-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import TableBox from '@/components/TableBox.vue';
import router from '@/router';
import _ from 'lodash';

export default {
  name: 'TaskListStore',
  components: {
    TableBox
  },
  metaInfo() {
    return {
      title: 'Task List',
      meta: [
        {
          name: 'description',
          content: 'View Tasks'
        }
      ]
    };
  },
  mounted() {
    // if (_.isEmpty(this.$route.query)) {
    //   this.listTasks({
    //     query: {
    //       ...this.$route.query,
    //       startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    //       enddate: moment().format('YYYY-MM-DD')
    //     }
    //   });
    // } else {
    this.listTasks({ query: this.$route.query });
    // }
  },
  beforeRouteUpdate(to, from, next) {
    this.listTasks({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Task List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Task Name',
          textKey: 'taskName',
          class: { 'text-center': true },
          width: '17%'
        },
        {
          type: 'string',
          headerText: 'View Attachments',
          urlKey: 'Link',
          class: { 'text-center': true },
          width: '17%'
        },
        {
          type: 'string',
          headerText: 'Description',
          textKey: 'taskDescription',
          class: { 'text-center': true },
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Communication Type',
          class: { 'text-center': true },
          textKey: 'communicationMode',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Date',
          textKey: 'endDate',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '13%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '11%',
          functions: {
            open: false,
            edit: false,
            submit: true
          }
        }
      ],
      searchItems: ['Task Name', 'Store Id']
    };
  },
  computed: {
    ...mapState('cst', ['loading', 'baseUrl', 'tasks', 'pagination', 'downloadReportList']),
    newTasks() {
      return this.tasks.map(i => ({
        ...i,

        Link: `/cstSubmission/new/${i.id}`
      }));
    }
  },
  methods: {
    ...mapActions('cst', ['listTasks', 'download', 'getOne']),

    onSubmit({ row }) {
      router.push(`/cstSubmission/new/${row.id}`);
    }
  }
};
</script>
