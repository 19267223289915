<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="newTasks"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="ifAdmin()"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :filterOptions="filterOptions"
      :showFilter="true"
      :searchItems="searchItems"
      @delete="onDelete"
      addText="Add New Task"
      emptyText="No Task found."
      @add="onAdd"
      @edit="onEdit"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import FeedbackListBox from '../../components/FeedbackListBox.vue';
import router from '@/router';
import _ from 'lodash';

export default {
  name: 'CSTTaskList',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Task List',
      meta: [
        {
          name: 'description',
          content: `Manage Videos List`
        }
      ]
    };
  },
  mounted() {
    // if (_.isEmpty(this.$route.query)) {
    //   this.listTasks({
    //     query: {
    //       ...this.$route.query,
    //       startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    //       enddate: moment().format('YYYY-MM-DD')
    //     }
    //   });
    // } else {
    this.listTasks({ query: this.$route.query });
    // }
  },
  beforeRouteUpdate(to, from, next) {
    this.listTasks({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Task List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Task Name',
          class: { 'text-center': true },
          textKey: 'taskName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'View Attachment',
          class: { 'text-center': true },
          urlKey: 'Link',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Description',
          class: { 'text-center': true },
          textKey: 'taskDescription',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Active',
          class: { 'text-center': true },
          textKey: 'active',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Date',
          class: { 'text-center': true },
          textKey: 'date',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Communication Type',
          class: { 'text-center': true },
          textKey: 'communicationMode',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'regions',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'clusters',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeids',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Role',
          class: { 'text-center': true },
          textKey: 'roles',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Uploaded At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '8%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '11%',
          functions: {
            edit: true,
            delete: true
          }
        }
      ],
      searchItems: ['Task Name', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Active-True', text: 'Active-True' },
        { value: 'Active-False', text: 'Active-False' }
      ]
    };
  },
  computed: {
    ...mapState('cst', ['loading', 'baseUrl', 'tasks', 'pagination']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    newTasks() {
      return this.tasks.map(i => ({
        ...i,

        Link: `${this.baseUrl}/${i.id}`
      }));
    }
  },
  methods: {
    ...mapActions('cst', ['listTasks', 'getOne', 'postOne', 'deleteOne']),
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'ADMIN');

      if (isAdmin.length) {
        return true;
      }
      return false;
    },
    onAdd() {
      router.push('/cstTask/new');
    },
    onEdit({ row }) {
      router.push(`/cstTask/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  }
};
</script>
