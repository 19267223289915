<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Add Attachments
            <span class="text-danger">*</span>
          </template>
          <!-- <input type="file" multiple ref="attachments" @change="fileOnChange" /> -->
          <input type="file" @change="uploadFile($event)" />
        </b-form-group>
        <div v-if="this.formType === 'new'">
          <li v-for="item in this.form.attachments" :key="item.id">
            {{ item.name }}
            <b-button class="box-shadow2" @click="removeFile(item)" variant="danger">
              <font-awesome-icon :icon="['fas', 'trash']" class="mr-10" />
            </b-button>
          </li>
        </div>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Select Option
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-option"
            placeholder="Select option"
            v-model="form.option"
            :options="[
              'Acknowledgement of Communication Receipt',
              'Team Briefing Done',
              'Execution Done',
              'Photos Shared (if required)'
            ]"
            :state="$v.form.option.$dirty ? !$v.form.option.$error : null"
          ></multiselect>
        </b-form-group>
        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Store Ids
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="storeId"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Store Ids"
            label="text"
            track-by="value"
            :options="stores"
          ></multiselect>
          <b-form-text id="input-role-help"
            >Note: The first selected store id will be the primary store id of CheckList for all
            operations.</b-form-text
          >
        </b-form-group>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label> Attachments of task </template>
          <li v-for="item in this.attachments" :key="item.id">
            <a :href="item" target="_blank">{{ item }}</a>
            <!-- <b-link >{{ item }}</b-link> -->
            <!-- <b-link :to="'/attachments/' + item.id">{{ item }}</b-link> -->
            <!-- <b-button @click="downloadAttachments" class="downloadButton" variant="success">
              <font-awesome-icon :icon="['fas', 'download']" class="mr-10" />
            </b-button> -->
          </li>
        </b-form-group>
        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button type="submit" size="sm" variant="success" :disabled="loading">
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/cstTask1` }">
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'CSTSubmissionFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    taskType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          name: ''
        }
      ],
      attachments: [],
      storeId: '',
      stores: [],
      formLoaded: true,
      form: {
        option: '',
        attachments: [],
        storeId: ''
      }
    };
  },
  validations() {
    const formValidation = {
      option: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.option.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.getStoreByUser();

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Task' : 'Update Task';
    },
    ...mapState('cst', ['loading', 'task']),
    ...mapState('auth', ['user']),
    ...mapState('cstSubmission', ['store'])
  },
  methods: {
    ...mapActions('cstSubmission', ['listTask', 'getOne', 'postOne', 'patchOne', 'uploadTask', 'getStoreByUser']),
    // fileOnChange() {
    //   let files = this.$refs.attachments.files;

    //   if (!files.length) {
    //     return false;
    //   }

    //   for (let i = 0; i < files.length; i++) {
    //     this.form.attachments.push(files[i]);
    //   }
    //   this.uploadTask({ file: this.form.attachments });
    // },
    uploadFile(event) {
      this.file = event.target.files[0];
      this.uploadTask({ file: this.file });
      for (let i = 0; i < event.target.files.length; i++) {
        this.form.attachments.push(event.target.files[i]);
      }
    },

    removeFile(item) {
      if (this.form.attachments.includes(item)) {
        this.form.attachments.splice(this.form.attachments.indexOf(item), 1);
      }
    },
    onSubmit() {
      this.storeIdError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (this.formType === 'new') {
        const attachmentsName = [];
        this.form.attachments.map(({ name }) => attachmentsName.push(name));

        const taskSubmission = {
          optionSelected: this.form.option,
          cstTask: this.$route.params.id,
          attachmentsName: attachmentsName || [],
          storeId: this.storeId.value
        };
        this.$emit('add', { taskSubmission });
      }
      return false;
    }
  },
  watch: {
    task(newValue) {
      const oldAttachments = newValue.attachments;
      this.attachments = oldAttachments;
    },
    store(newValue) {
      this.stores = newValue.map(store => ({ text: store.storeid, value: store.storeid }));
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}
.date {
  margin-left: -10px;
}
.upload {
  margin-top: 8px;
}
.videoPlayer {
  width: 25%;
}

.downloadButton {
  margin-top: 9px;
  margin-left: 9px;
}
</style>
