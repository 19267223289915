var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading || !_vm.formLoaded)?_c('div',[_c('span',{staticClass:"spinner"})]):_vm._e(),(!_vm.loading && _vm.formLoaded)?_c('div',[_c('b-form',{class:{
        'p-3 box-shadow2 rounded': !_vm.isDarkMode,
        'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
      },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"group-products","label-for":"input-products"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Attachments "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,2211612712)},[_c('input',{attrs:{"type":"file"},on:{"change":function($event){return _vm.uploadFile($event)}}})]),(this.formType === 'new')?_c('div',_vm._l((this.form.attachments),function(item){return _c('li',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" "),_c('b-button',{staticClass:"box-shadow2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeFile(item)}}},[_c('font-awesome-icon',{staticClass:"mr-10",attrs:{"icon":['fas', 'trash']}})],1)],1)}),0):_vm._e(),_c('b-form-group',{attrs:{"id":"group-product","label-for":"input-product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Option "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,442137320)},[_c('multiselect',{attrs:{"id":"input-option","placeholder":"Select option","options":[
            'Acknowledgement of Communication Receipt',
            'Team Briefing Done',
            'Execution Done',
            'Photos Shared (if required)'
          ],"state":_vm.$v.form.option.$dirty ? !_vm.$v.form.option.$error : null},model:{value:(_vm.form.option),callback:function ($$v) {_vm.$set(_vm.form, "option", $$v)},expression:"form.option"}})],1),_c('b-form-group',{attrs:{"id":"group-products","label-for":"input-products"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Store Ids "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,791302370)},[_c('multiselect',{ref:"multiselect",attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Select Store Ids","label":"text","track-by":"value","options":_vm.stores},model:{value:(_vm.storeId),callback:function ($$v) {_vm.storeId=$$v},expression:"storeId"}}),_c('b-form-text',{attrs:{"id":"input-role-help"}},[_vm._v("Note: The first selected store id will be the primary store id of CheckList for all operations.")])],1),_c('b-form-group',{attrs:{"id":"group-product","label-for":"input-product"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Attachments of task ")]},proxy:true}],null,false,374729557)},_vm._l((this.attachments),function(item){return _c('li',{key:item.id},[_c('a',{attrs:{"href":item,"target":"_blank"}},[_vm._v(_vm._s(item))])])}),0),_c('b-form-group',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"submit","size":"sm","variant":"success","disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","variant":"warning","to":{ path: "/cstTask1" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }