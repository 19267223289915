<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <cst-submission-form-box
        list-url="/cstSubmission"
        :id="id"
        task-type="task"
        :form-type="formType"
        @add="onAdd"
        @edit="onEdit"
      ></cst-submission-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import cstSubmissionFormBox from '../../components/cstSubmissionFormBox.vue';

export default {
  name: 'CSTForm',
  components: {
    cstSubmissionFormBox
  },
  async mounted() {
    if (this.$route.name === 'cst-task-submission') {
      this.formType = 'new';
      this.id = this.$route.params.id;
      await this.getOne({
        type: 'task',
        id: this.id,
        router
      });
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'task',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Submit Task' : 'Submit Task';
    },
    ...mapState('cst', ['loading'])
  },
  methods: {
    ...mapActions('cstSubmission', ['getOne', 'postOne', 'patchOne']),
    ...mapActions('cst', ['getOne']),
    onAdd({ taskSubmission }) {
      this.postOne({
        taskSubmission,
        router,
        redirectUrl: '/cstTask1'
      });
    },
    onEdit({ taskSubmission }) {
      this.patchOne({
        type: 'task',
        id: this.id,
        taskSubmission,
        router,
        redirectUrl: '/cstTask1'
      });
    }
  }
};
</script>
