<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="group-product" label-for="input-product">
          <div v-if="this.attachments.length != 0">
            <li v-for="item in this.attachments" :key="item.id">
              <a :href="item" target="_blank">{{ item }}</a>
            </li>
          </div>
          <div class="text-center elsePart" v-else><h3>No Attachments Found.</h3></div>
        </b-form-group>
        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <!-- <b-button type="submit" size="sm" variant="success" :disabled="loading">
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button> -->
              <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/cstSubmission` }">
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'CSTSubmissionFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    submissionType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          name: ''
        }
      ],
      attachments: [],
      storeids: [],
      formLoaded: true,
      form: {
        option: '',
        attachments: [],
        storeids: []
      }
    };
  },
  validations() {
    const formValidation = {
      option: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.option.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.formType === 'new') {
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Task' : 'Update Task';
    },
    ...mapState('cst', ['loading', 'task']),
    ...mapState('auth', ['user']),
    ...mapState('cstSubmission', ['store', 'submission'])
  },
  methods: {
    ...mapActions('cstSubmission', ['listTask', 'getOne', 'postOne', 'patchOne', 'uploadTask', 'getStoreByUser']),
    fileOnChange() {
      let files = this.$refs.attachments.files;

      if (!files.length) {
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        this.form.attachments.push(files[i]);
      }
      this.uploadTask({ file: this.form.attachments });
    },

    removeFile(item) {
      if (this.form.attachments.includes(item)) {
        this.form.attachments.splice(this.form.attachments.indexOf(item), 1);
      }
    },
    onSubmit() {
      this.storeIdError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (this.formType === 'new') {
        const attachmentsName = [];
        this.form.attachments.map(({ name }) => attachmentsName.push(name));

        const taskSubmission = {
          option: this.form.option,
          cstTask: this.$route.params.id,
          attachmentsName: attachmentsName || []
        };
        this.$emit('add', { taskSubmission });
      }
      return false;
    }
  },
  watch: {
    submission(newValue) {
      this.attachments = newValue.attachments;
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}
.date {
  margin-left: -10px;
}
.upload {
  margin-top: 8px;
}
.videoPlayer {
  width: 25%;
}

.downloadButton {
  margin-top: 9px;
  margin-left: 9px;
}

.elsePart {
  margin-top: 30px;
}
</style>
