<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <submission-data
        list-url="/cstSubmission"
        :id="id"
        submission-type="submission"
        :from-type="formType"
        @edit="onEdit"
      ></submission-data>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import SubmissionData from '../../components/SubmissionData.vue';

export default {
  name: 'CSTSubmitData',
  components: {
    SubmissionData
  },
  async mounted() {
    if (this.$route.name === 'cst-submission-attachments') {
      this.formType = 'new';
      this.id = this.$route.params.id;
      await this.getOne({
        type: 'submission',
        id: this.id,
        router
      });
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'submission',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Attachments of Submitted Task' : 'Attachments of Submitted Task';
    },
    ...mapState('cstSubmission', ['loading'])
  },
  methods: {
    ...mapActions('cstSubmission', ['getOne', 'postOne', 'patchOne']),
    onAdd({ task }) {
      this.postOne({
        task,
        router,
        redirectUrl: '/cstTask'
      });
    },
    onEdit({ submission }) {
      this.patchOne({
        type: 'submission',
        id: this.id,
        submission,
        router,
        redirectUrl: '/cstSubmission'
      });
    }
  }
};
</script>
