<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="newSubmissions"
      :baseUrl="baseUrl"
      :loading="loading"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      @delete="onDelete"
      @edit="onEdit"
      emptyText="No Task found."
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import FeedbackListBox from '../../components/FeedbackListBox.vue';
import _ from 'lodash';

export default {
  name: 'CSTTaskList',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Submissions List',
      meta: [
        {
          name: 'description',
          content: `Manage Submissions List`
        }
      ]
    };
  },
  mounted() {
    // if (_.isEmpty(this.$route.query)) {
    //   this.listSubmissions({
    //     query: {
    //       ...this.$route.query,
    //       startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    //       enddate: moment().format('YYYY-MM-DD')
    //     }
    //   });
    // } else {
    this.listSubmissionsById({ query: this.$route.query });
    // }
  },
  beforeRouteUpdate(to, from, next) {
    this.listSubmissionsById({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Submission List',

      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'CST Task',
          class: { 'text-center': true },
          textKey: 'cstTask',
          width: '16%'
        },
        {
          type: 'string',
          headerText: 'Attachment URL',
          class: { 'text-center': true },
          urlKey: 'Link',
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'Option Selected',
          class: { 'text-center': true },
          textKey: 'optionSelected',
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '17%'
        },

        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '20%'
        }
      ],
      searchItems: ['Task Name']
    };
  },
  computed: {
    ...mapState('cstSubmissionId', ['loading', 'baseUrl', 'submissions', 'pagination']),
    newSubmissions() {
      return this.submissions.map(i => ({
        ...i,

        Link: `/cstSubmission/attachments/${i.id}`
      }));
    }
  },
  methods: {
    ...mapActions('cstSubmissionId', ['listSubmissionsById'])
  }
};
</script>
